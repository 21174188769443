import { capitalizeName } from "./capitalizeName";

export const getERPName = (connection?: ConnectionT) => {
  if (!connection || !connection?.connection_type) return "";

  if (connection.connection_type === "QBD") {
    return "Quickbooks Desktop";
  }

  if (connection.connection_type === "sage300") {
    return "Sage 300";
  }

  return capitalizeName(connection.connection_type);
};

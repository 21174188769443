import { fetchWithAuth } from "../interceptor";
import {
  APIRequestCreateShareholderT,
  APIRequestUpdateCompanyT,
  APIRequestUpdateShareholderT,
  APIResponseCompanySearchT,
  APIResponseGetActivity,
  APIResponseGetCompanyCustomers,
  APIResponseGetCompanyMetricsT,
  APIResponseGetCompanyT,
  APIResponseGetCompanyVendors,
  APIResponseGetERPTaxRates,
  APIResponseGetLatestBatchT,
  APIResponseGetShareholdersT,
  APIResponseGetSubscriptions,
  APIResponseGetTeammates,
  APIResponseUpdateCompanyT,
  APIResponseUpdateSubscriptions,
  ApiResponseAcceptEarlyPayAgreement,
  ApiResponseBillCountT,
} from "./types";

export const searchCompany = async (props: {
  query: string;
}): Promise<APIResponseCompanySearchT> => {
  const { query } = props;

  return fetchWithAuth(`/company/search?search=${query}`, {
    method: "GET",
  });
};

export const getInviteDetails = async (token: string) => {
  try {
    const rawResponse = await fetch(`/api/company/invite/${token}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const content = await rawResponse.json();

    return content;
  } catch (error) {
    console.error(error);
    return { success: false, message: "Something went wrong" };
  }
};

export const createShareholder = async (
  shareholder: APIRequestCreateShareholderT
) => {
  return fetchWithAuth(`/company/shareholders`, {
    method: "POST",
    body: JSON.stringify(shareholder),
  });
};

export const deleteShareholder = async (shareholder_id: number) => {
  return fetchWithAuth(`/company/shareholders/${shareholder_id}`, {
    method: "DELETE",
  });
};

export const updateShareholder = async (
  id: number,
  shareholder: APIRequestUpdateShareholderT
) => {
  return fetchWithAuth(`/company/shareholders/${id}`, {
    method: "PUT",
    body: JSON.stringify(shareholder),
  });
};

export const getShareholders =
  async (): Promise<APIResponseGetShareholdersT> => {
    return fetchWithAuth(`/company/shareholders`, {
      method: "GET",
    });
  };

export const updateCompany = async (
  company: APIRequestUpdateCompanyT
): Promise<APIResponseUpdateCompanyT> => {
  return fetchWithAuth(`/company`, {
    method: "PUT",
    body: JSON.stringify({ company }),
  });
};

export const getCompany = async (): Promise<APIResponseGetCompanyT> => {
  return fetchWithAuth("/company", { method: "GET" });
};

export const getCompanyMetrics = async (
  type: "bill" | "invoice"
): Promise<APIResponseGetCompanyMetricsT> => {
  return fetchWithAuth(`/company/metrics?type=${type}`, { method: "GET" });
};

export const forceCompanySync = async ({
  type,
  entityId,
}: {
  type?: "bill" | "invoice" | "contact" | "initial";
  entityId?: string;
}): Promise<APIResponse> => {
  let queryString = "";

  if (type) queryString = `?type=${type}`;
  if (entityId) queryString += `?entityId=${entityId}`;

  return fetchWithAuth(`/company/sync${queryString}`, {
    method: "POST",
  });
};

export const manualEPProcess = async (props: {
  type: string;
  assetIds?: number[];
}): Promise<APIResponse> => {
  const { type, assetIds } = props;
  return fetchWithAuth(`/company/manualEP`, {
    method: "POST",
    body: JSON.stringify({
      type: type,
      assetIds,
    }),
  });
};

export const getLatestBatch = async (): Promise<APIResponseGetLatestBatchT> => {
  return await fetchWithAuth(`/company/batch`, {
    method: "GET",
  });
};

export const getCompanyTeammates =
  async (): Promise<APIResponseGetTeammates> => {
    return await fetchWithAuth(`/company/teammates`, {
      method: "GET",
    });
  };

export const getCompanySubscriptions = async (
  email?: string,
  companyId?: string
): Promise<APIResponseGetSubscriptions> => {
  if (email && companyId) {
    return fetchWithAuth(
      `/company/subscriptions?companyId=${companyId}&email=${email}`,
      {
        method: "GET",
      }
    );
  }
  return fetchWithAuth(`/company/subscriptions`, {
    method: "GET",
  });
};

export const updateCompanySubscriptions = async (
  subscriptions: SubscriptionT[]
): Promise<APIResponseUpdateSubscriptions> => {
  return fetchWithAuth(`/company/subscriptions/update`, {
    method: "PUT",
    body: JSON.stringify({ subscriptions }),
  });
};

export const getCompanyVendors = async (
  companyId: number
): Promise<APIResponseGetCompanyVendors> => {
  return fetchWithAuth(`/company/${companyId}/vendors`, {
    method: "GET",
  });
};

export const getCompanyCustomers = async ({
  page = 1,
  limit = 50,
  payers = "customer",
  query = "",
}): Promise<APIResponseGetCompanyCustomers> => {
  const url = `/company/customers?type=${payers}`;
  let newURL = `${url}&page=${page}`;
  if (limit) newURL += `&limit=${limit}`;
  if (query) newURL += `&q=${query}`;

  return fetchWithAuth(newURL, {
    method: "GET",
  });
};

export const getERPTaxRates = async (): Promise<APIResponseGetERPTaxRates> => {
  return fetchWithAuth("/company/erp/getTaxRates", { method: "GET" });
};

export const sendCompanyApprovalReq = async (): Promise<APIResponse> => {
  return fetchWithAuth("/company/requestApproval", { method: "POST" });
};

export const getCompanyActivity = async (): Promise<APIResponseGetActivity> => {
  return fetchWithAuth("/company/activity", { method: "GET" });
};

export const deleteCompanyTeammate = async (props: {
  teammateId: number;
}): Promise<APIResponse> => {
  const { teammateId } = props;

  return fetchWithAuth(`/company/teammate/${teammateId}`, { method: "DELETE" });
};

export const inviteTeammate = async (
  senderName: string,
  senderEmail: string,
  company: string,
  email: string,
  firstName: string | null,
  lastName: string | null,
  role: string,
  isAuthorizedSignatory?: boolean
): Promise<APIResponse> => {
  const content = await fetchWithAuth(`/company/invite`, {
    method: "POST",

    body: JSON.stringify({
      email,
      role,
      first_name: firstName,
      last_name: lastName,
      isAuthorizedSignatory,
    }),
  });

  window &&
    window.Intercom &&
    window.Intercom("trackEvent", "team-invite", {
      senderName,
      senderEmail,
      company,
      invitedEmail: email,
      version: process.env.NEXT_PUBLIC_APP_VERSION,
    });

  return content;
};

export const updateTeammate = async (
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  avatar_url?: string,
  userId?: number,
  enabled?: boolean,
  roles?: string[]
): Promise<APIResponse> => {
  return fetchWithAuth(`/auth/user/${userId}`, {
    method: "PUT",
    body: JSON.stringify({
      user: {
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        avatar_url,
        userId,
        enabled,
        roles,
      },
    }),
  });
};

export const getActionableBillsCount =
  async (): Promise<ApiResponseBillCountT> => {
    return fetchWithAuth(`/company/bills/count`, {
      method: "GET",
    });
  };

export const acceptEarlyPaymentAgreement =
  async (): Promise<ApiResponseAcceptEarlyPayAgreement> => {
    return fetchWithAuth(`/company/acceptAgreement`, {
      method: "POST",
    });
  };

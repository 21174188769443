export const formatMoney = (
  money?: string | number | null,
  currency?: string
) => {
  if (money === undefined || money === null) return "";
  const formatter = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: currency || "CAD",
  });

  if (typeof money === "number") return formatter.format(money);
  if (typeof money === "string") return formatter.format(parseFloat(money));
};

// Formats a company address into a string.
// e.g. "Apt 4B, 123 Main St Cityville, AB T0L0X0"
export const formatCompanyAddress = (
  company: Partial<CompanyType> | undefined
) => {
  const baseAddress = [];

  if (company?.address_line_2) {
    baseAddress.push(company.address_line_2);
  }

  if (company?.address_line_1 && company.address_city) {
    baseAddress.push(`${company.address_line_1} ${company.address_city}`);
  }

  if (company?.address_state && company?.address_zip) {
    baseAddress.push(
      `${company.address_state} ${company.address_zip.toUpperCase()}`
    );
  }

  if (company?.address_state && !company?.address_zip) {
    baseAddress.push(`${company.address_state}`);
  }

  return baseAddress.join(", ");
};

// Formats a company address into an array.
// e.g. ["Apt 4B 123 Main St,", "Cityville, AB T0L0X0"]
export const formatAddress = (company: Partial<CompanyType> | undefined) => {
  const address = [];
  const region = [];

  if (company?.address_line_2) {
    address.push(company.address_line_2);
  }

  if (company?.address_line_1) {
    address.push(company.address_line_1 + ",");
  }

  if (company?.address_city) {
    region.push(company.address_city + ",");
  }

  if (company?.address_state) {
    region.push(company.address_state);
  }

  if (company?.address_zip) {
    region.push(company.address_zip.toUpperCase());
  }

  return [address.join(" "), region.join(" ")];
};

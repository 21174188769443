import Decimal from "decimal.js-light";

export type CreditApplicationT = {
  totalAmountApplied: number;
  totalApproved: number;
  applications: {
    creditId: number;
    amount: number;
  }[];
};

export function groupInvoicesByContact(
  records: { invoice: InvoiceType; holdback: number }[]
): Record<string, { invoice: InvoiceType; holdback: number }[]> {
  return records.reduce(
    (grouped, record) => {
      // Get the contact_id from the first bill, or skip if no bills
      const contactId = record.invoice.ContactInvoiceBills?.[0]?.contact_id;

      if (!contactId) {
        return grouped;
      }

      // Initialize array for this contact if it doesn't exist
      if (!grouped[contactId]) {
        grouped[contactId] = [];
      }

      // Add invoice to the appropriate group
      grouped[contactId].push(record);

      return grouped;
    },
    {} as Record<string, { invoice: InvoiceType; holdback: number }[]>
  );
}

export function applyCreditsToInvoices(
  invoices: { invoice: InvoiceType; holdback: number }[],
  credits: { invoice: InvoiceType; holdback: number }[]
): Record<number, CreditApplicationT> {
  let result: Record<number, CreditApplicationT> = {};

  const contactGroupedInvoices = groupInvoicesByContact(invoices);
  const contactGroupedCredits = groupInvoicesByContact(credits);

  for (const contactId in contactGroupedInvoices) {
    const contactInvoices = contactGroupedInvoices[contactId];
    const contactCredits = contactGroupedCredits[contactId] || [];

    const contactResult = applyCreditsToInvoicesForContact(
      contactInvoices,
      contactCredits
    );
    result = {
      ...result,
      ...contactResult,
    };
  }

  return result;
}

export function applyCreditsToInvoicesForContact(
  invoiceRecords: { invoice: InvoiceType; holdback: number }[],
  creditRecords: { invoice: InvoiceType; holdback: number }[]
): Record<number, CreditApplicationT> {
  const result: Record<number, CreditApplicationT> = {};
  const creditsState = [...creditRecords]
    .sort((a, b) => b.invoice.id - a.invoice.id)
    .map((x) => ({
      credit: x,
      remainingAmount: new Decimal(x.invoice.totals.buyerBalance).toNumber(),
    }));

  for (const record of invoiceRecords) {
    let remainingAmount = new Decimal(record.invoice.totals.buyerBalance).sub(
      record.holdback
    );

    if (!result[record.invoice.id]) {
      result[record.invoice.id] = {
        totalApproved: remainingAmount.toNumber(),
        totalAmountApplied: 0,
        applications: [],
      };
    }

    while (remainingAmount.gt(0)) {
      const currentCredit = creditsState[creditsState.length - 1];

      if (!currentCredit) break;

      const creditAmount = new Decimal(currentCredit.remainingAmount);

      if (!creditAmount.gte(0) || !remainingAmount.gte(0)) {
        break;
      }
      const appliedAmount = new Decimal(
        Math.min(creditAmount.toNumber(), remainingAmount.toNumber())
      );
      remainingAmount = remainingAmount.sub(appliedAmount);

      if (appliedAmount.eq(creditAmount)) {
        creditsState.pop();
      } else {
        creditsState[creditsState.length - 1].remainingAmount = creditAmount
          .sub(appliedAmount)
          .toNumber();
      }

      result[record.invoice.id].applications.push({
        amount: appliedAmount.toNumber(),
        creditId: currentCredit.credit.invoice.id,
      });
      result[record.invoice.id].totalApproved = remainingAmount.toNumber();
      result[record.invoice.id].totalAmountApplied = new Decimal(
        record.invoice.totals.initialBalance
      )
        .sub(remainingAmount)
        .toNumber();
    }
  }

  return result;
}

import { fetchWithAuth } from "../interceptor";
import {
  APIRequestCreateCompanyERPCustomerT,
  APIRequestCreateCompanyERPGLAccountT,
  APIRequestCreateCompanyERPVendorT,
  APIResponseGetChartOfAccountsT,
  APIResponseGetERPConnectionStatusT,
  APIResponseGetERPCustomersT,
  APIResponseGetERPSupplierCreditsT,
  APIResponseGetERPVendorsT,
  APIResponseGetFinancialAccountsT,
  APIResponseGetOrganizationalEntitiesT,
  APIResponseUpdateOrganizationalEntityT,
  ManualERPConnectionPayload,
} from "./types";

export const createERPVendor = async (
  body: APIRequestCreateCompanyERPVendorT
) => {
  return fetchWithAuth(`/company/erp/vendors`, {
    method: "POST",
    body: JSON.stringify(body),
  });
};

export const createERPCustomer = async (
  body: APIRequestCreateCompanyERPCustomerT
) => {
  return fetchWithAuth(`/company/erp/customers`, {
    method: "POST",
    body: JSON.stringify(body),
  });
};

export const getERPVendors = async (params?: {
  search?: string;
  selectedIds?: string[];
}): Promise<APIResponseGetERPVendorsT> => {
  const queryParams = new URLSearchParams();

  if (params?.search) {
    queryParams.append("search", params.search);
  }

  if (params?.selectedIds && params.selectedIds.length > 0) {
    queryParams.append("selectedIds", params.selectedIds.join(","));
  }

  const queryString = queryParams.toString()
    ? `?${queryParams.toString()}`
    : "";

  return fetchWithAuth(`/company/erp/vendors${queryString}`, {
    method: "GET",
  });
};

export const getERPCustomers =
  async (): Promise<APIResponseGetERPCustomersT> => {
    return fetchWithAuth(`/company/erp/customers`, {
      method: "GET",
    });
  };

export const syncReleaseFundsEntries = async (props: {
  id: number;
  type?: "seller" | "buyer";
}): Promise<APIResponse> => {
  const { id, type = "buyer" } = props;
  return fetchWithAuth(`/invoices/${id}/erp/release-funds`, {
    method: "POST",
    body: JSON.stringify({
      type,
    }),
  });
};

export const syncRepaymentEntries = async (props: {
  id: number;
  type?: "seller" | "buyer";
  method?: "supplier-credit" | "final-payment";
}): Promise<APIResponse> => {
  const { id, type = "buyer", method = "final-payment" } = props;
  return fetchWithAuth(`/invoices/${id}/erp/repayment`, {
    method: "POST",
    body: JSON.stringify({
      type,
      method,
    }),
  });
};

export const createERPGLAccount = async (
  body: APIRequestCreateCompanyERPGLAccountT
) => {
  return fetchWithAuth(`/company/erp/accounts`, {
    method: "POST",
    body: JSON.stringify(body),
  });
};

export const getERPConnectionStatus =
  async (): Promise<APIResponseGetERPConnectionStatusT> => {
    return fetchWithAuth(`/company/erp/connection`, {
      method: "GET",
    });
  };

export const getERPSupplierCredits = async ({
  billIds,
}: {
  billIds: number[];
}): Promise<APIResponseGetERPSupplierCreditsT> => {
  return fetchWithAuth(
    `/company/erp/supplierCredits?billIds=${billIds.join(",")}`,
    {
      method: "GET",
    }
  );
};

export const getERPFinancialAccounts = async (params?: {
  search?: string;
  selectedIds?: string[];
}): Promise<APIResponseGetFinancialAccountsT> => {
  const queryParams = new URLSearchParams();

  if (params?.search) {
    queryParams.append("search", params.search);
  }

  if (params?.selectedIds && params.selectedIds.length > 0) {
    queryParams.append("selectedIds", params.selectedIds.join(","));
  }

  const queryString = queryParams.toString()
    ? `?${queryParams.toString()}`
    : "";

  return fetchWithAuth(`/company/erp/financialAccounts${queryString}`, {
    method: "GET",
  });
};

export const getERPChartOfAccounts = async (params?: {
  search?: string;
  selectedIds?: string[];
  section?: "revenue" | "expense" | "other";
}): Promise<APIResponseGetChartOfAccountsT> => {
  const queryParams = new URLSearchParams();

  if (params?.search) {
    queryParams.append("search", params.search);
  }

  if (params?.selectedIds && params.selectedIds.length > 0) {
    queryParams.append("selectedIds", params.selectedIds.join(","));
  }

  if (params?.section) {
    queryParams.append("section", params.section);
  }

  const queryString = queryParams.toString()
    ? `?${queryParams.toString()}`
    : "";

  return fetchWithAuth(`/company/erp/getChartOfAccounts${queryString}`, {
    method: "GET",
  });
};

export const deleteConnection = async (id: number): Promise<APIResponse> => {
  return fetchWithAuth(`/company/connection/${id}`, {
    method: "DELETE",
  });
};

export const getOrganizationalEntities =
  async (): Promise<APIResponseGetOrganizationalEntitiesT> => {
    return fetchWithAuth(`/company/erp/organizationalEntities`, {
      method: "GET",
    });
  };

export const createOAuthAccumaticaConnection = async (
  code: string,
  connection_id: number
): Promise<APIResponse> => {
  return fetchWithAuth(`/company/connections/accumatica/oauth`, {
    method: "POST",
    body: JSON.stringify({ code, connection_id }),
  });
};

export const updateOrganizationalEntity = async (
  id: number,
  payload: Partial<
    Omit<
      OrganizationalEntityT,
      "id" | "company_id" | "connection_id" | "erp_id"
    >
  >
): Promise<APIResponseUpdateOrganizationalEntityT> => {
  return fetchWithAuth(`/company/organizationalEntities/${id}`, {
    method: "PUT",
    body: JSON.stringify(payload),
  });
};

export const updateEntityConfiguration = async (payload: {
  entity_ids: number[];
  default_to_top_level: boolean;
}) => {
  return fetchWithAuth(`/company/erp/entities/enable`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

export const createManualERPConnection = async (
  manualConnectionPayload: ManualERPConnectionPayload
): Promise<APIResponse & { authFlowUrl?: string }> => {
  const { type, ...payload } = manualConnectionPayload;
  return fetchWithAuth(`/company/connections/${type}`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

"use client";

import { Combobox, Transition } from "@headlessui/react";
import clsx from "clsx";
import {
  ChangeEvent,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { debounce } from "../../../helpers/debounce";
import { Icon } from "../../Icon";
import { TextButton } from "../../TextButton";

type OptionT = {
  label: string;
  value: string;
  original?: unknown;
  subtitle?: string;
};
type OptionsT = OptionT[];

export type PropsT = {
  onSelect: (item?: OptionT) => void;
  value?: OptionT;
  wide?: boolean;
  disabled?: boolean;
  getOptions: (query: string) => OptionsT | Promise<OptionsT>;
  "data-testid"?: string;
  onAddNew?: () => void;
  label: string;
  placeholder: string;
  debounceTime?: number;
  error?: ReactNode | null;
};

export const DropdownSearchInput = (props: PropsT) => {
  const {
    onSelect,
    value,
    wide,
    disabled,
    onAddNew,
    "data-testid": dataTestId = "dropdown-search-input",
    placeholder,
    label,
    getOptions,
    debounceTime = 300,
    error,
  } = props;

  const [selectedItem, setSelectedItem] = useState<typeof value | undefined>(
    value
  );

  const [query, setQuery] = useState(selectedItem?.label || "");
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstFetch, setIsFirstFetch] = useState(true);

  const [allItems, setAllItems] = useState<(typeof value)[]>([]);

  const handleGetOptions = useCallback(
    async (queryString: string) => {
      setIsLoading(true);
      const options = await getOptions(queryString);
      setIsFirstFetch(false);
      setIsLoading(false);
      setAllItems(options);
    },
    [getOptions]
  );

  useEffect(() => {
    handleGetOptions("");
  }, [handleGetOptions]);

  const debouncedGetOptions = useMemo(
    () =>
      debounceTime > 0
        ? debounce(handleGetOptions, debounceTime)
        : handleGetOptions,
    [handleGetOptions, debounceTime]
  );

  const handleSearch = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
      debouncedGetOptions(event.target.value);
    },
    [debouncedGetOptions]
  );

  const handleClear = () => {
    setSelectedItem(undefined);
    setQuery("");
    onSelect(undefined);
  };

  const handleSelect = async (item: OptionT) => {
    onSelect(item);
    setSelectedItem(item);
    setQuery(item?.label || item?.value || "");
  };

  return (
    <>
      <Combobox
        as="div"
        value={selectedItem}
        onChange={(item: OptionT) => handleSelect(item)}
        by="value"
        nullable
        data-testid={dataTestId}
        className={clsx("DropdownSearchInput relative w-full", {
          "w-full md:w-80": !wide,
        })}
      >
        <>
          <div className="pointer-events-none absolute left-0 top-3.5 pl-4">
            <Icon icon="magnifying-glass" size="20" iconStyle="fill-gray-400" />
          </div>

          <Combobox.Input
            data-testid={`${dataTestId}-search-box`}
            name="comboboxInput"
            placeholder=" "
            type="text"
            displayValue={(item: OptionT) => item?.label || ""}
            value={query}
            onChange={(e) => {
              handleSearch(e);
            }}
            className={clsx(
              "z-10 block w-full appearance-none rounded-lg border border-gray-300 bg-white px-[15px] pb-2.5 pl-10 pr-10 pt-4",
              "peer placeholder:text-transparent focus:border-gray-900 focus:pb-2.5 focus:pt-4 focus:outline-none focus:ring-0",
              "overflow-hidden truncate text-sm text-gray-900 autofill:text-sm autofill:text-gray-900",
              {
                "pointer-events-none cursor-default !border-gray-100 !bg-gray-100":
                  disabled || (isFirstFetch && isLoading),
                "!border-orange-600 !bg-orange-100": error,
              }
            )}
          />

          {error && (
            <div className="mt-2 flex flex-row text-sm font-semibold text-orange-600">
              <Icon
                icon="warning-fill"
                size="16"
                iconStyle="fill-orange-600 mr-2 mt-1"
              />
              {error}
            </div>
          )}

          <Combobox.Label
            className={clsx(
              "absolute left-10 top-3.5 cursor-text whitespace-nowrap text-sm text-gray-500",
              "origin-[0] -translate-y-3 scale-75 transform duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-autofill:-translate-y-3 peer-autofill:scale-75 peer-focus:-translate-y-3 peer-focus:scale-75",
              "w-full overflow-hidden truncate pr-10 transition-all peer-focus:pr-0",
              { "!pointer-events-none !cursor-default": disabled }
            )}
          >
            {isLoading && !selectedItem
              ? "Loading..."
              : selectedItem
                ? label
                : placeholder}
          </Combobox.Label>

          {!disabled && (
            <Combobox.Button className="absolute top-0 z-0 h-12 w-full bg-transparent hover:cursor-text"></Combobox.Button>
          )}

          {(query.length > 0 || Boolean(value && value?.value)) &&
            !disabled && (
              <div
                className="absolute right-0 top-3.5 cursor-pointer pr-3"
                data-testid={`${dataTestId}-clear-button`}
                onClick={handleClear}
              >
                <Icon
                  icon="x-circle"
                  size="20"
                  iconStyle="fill-gray-400 hover:fill-gray-900"
                />
              </div>
            )}

          <Transition
            enter="transition duration-30 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-300 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            className="absolute z-20 w-full"
          >
            <Combobox.Options className="absolute z-10 mt-2 max-h-80 min-h-[20px] w-full overflow-y-auto rounded-lg border border-gray-200 bg-white p-4 shadow-lg">
              <li>
                <div className="flex justify-between">
                  <h2 className="bg-white text-sm font-semibold text-gray-900">
                    {placeholder}
                  </h2>
                  {onAddNew && (
                    <TextButton
                      label="Add new"
                      data-testid={`${dataTestId}-add-new-button`}
                      onClick={onAddNew}
                      disabled={!onAddNew}
                      icon="plus"
                    />
                  )}
                </div>

                {isLoading ? (
                  <div className="my-4 text-center text-sm text-gray-500">
                    Loading...
                  </div>
                ) : (
                  <ul className="mt-4 space-y-2">
                    {allItems.length > 0 ? (
                      allItems.map((item) => (
                        <Combobox.Option
                          key={item?.value}
                          value={item}
                          as={Fragment}
                        >
                          {({ active }) => (
                            <li
                              data-testid={`${dataTestId}-dropdown-option-${item?.value}`}
                              className={clsx(
                                "flex cursor-pointer select-none flex-col rounded-lg bg-gray-50 px-3 py-2 hover:bg-gray-100",
                                active && "!bg-gray-100"
                              )}
                            >
                              <div className="flex flex-col gap-1">
                                <span className="text-sm text-gray-900">
                                  {item?.label}
                                </span>
                                {item?.subtitle && (
                                  <span className="text-sm text-gray-500">
                                    {item?.subtitle}
                                  </span>
                                )}
                              </div>
                            </li>
                          )}
                        </Combobox.Option>
                      ))
                    ) : (
                      <div className="text-center text-sm text-gray-500">
                        None found
                      </div>
                    )}
                  </ul>
                )}
              </li>
            </Combobox.Options>
          </Transition>
        </>
      </Combobox>
    </>
  );
};

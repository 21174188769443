import { ComponentProps, useCallback, useState } from "react";
import { getERPVendors } from "../../../../apis/erp/api";
import { DropdownSearchInput } from "../../../shared/components";
import { IntegrationsChangesT } from "../types";

type PropsT = {
  changes?: IntegrationsChangesT;
  type: string;
  entity: OrganizationalEntityT | null;
  field: keyof IntegrationsChangesT;
  handleChange: <K extends keyof IntegrationsChangesT>(
    key: K,
    value: IntegrationsChangesT[K] | null
  ) => void;
};

type DropdownSearchInputProps = ComponentProps<typeof DropdownSearchInput>;

type UseQuicklyVendorReturnT = {
  dropdown: Pick<DropdownSearchInputProps, "onSelect" | "value" | "getOptions">;
  key: string;
};

export const useQuicklyVendor = ({
  entity,
  field,
  handleChange,
  type,
}: PropsT): UseQuicklyVendorReturnT => {
  const [selectedVendor, setSelectedVendor] = useState<{
    value: string;
    label: string;
  }>({
    value: "",
    label: "",
  });

  const actualColumn = entity?.[field];

  const getOptions = useCallback(
    async (query: string) => {
      const selectedIds: string[] = [];
      if (actualColumn) {
        selectedIds.push(actualColumn as string);
      }

      const response = await getERPVendors({
        search: query,
        selectedIds,
      });

      const options = (response.vendors || []).map((vendor) => ({
        label: vendor.company_name || "",
        value: vendor.erp_id || "",
      }));

      if (actualColumn) {
        const foundVendor = options.find((x) => x.value === actualColumn);
        if (foundVendor) {
          setSelectedVendor(foundVendor);
        }
      }

      return options;
    },
    [actualColumn]
  );

  return {
    dropdown: {
      onSelect: (option) => {
        handleChange(field, option?.value);
      },
      getOptions,
      value: {
        label: selectedVendor.label || "",
        value: selectedVendor.value || "",
      },
    },
    key: `${type}-${selectedVendor.value}`,
  };
};

import {
  Button,
  DayOfMonthSelector,
  Icon,
  InlineAlert,
  RadioInput,
  SelectInput,
  TextInput,
  Tooltip,
} from "@repo/ui";
import { PaymentCycleT } from "../../../../../apis/company";
import { capitalizeName } from "../../../helpers";

type PropsT = {
  onChange: (paymentCycles: PaymentCycleT[]) => void;
  payCycles: PaymentCycleT[];
  errors: Record<string, ErrorT>;
};
const daysOfTheWeek = [
  { label: "Sunday", value: "sunday" },
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
];
export default function PaymentCycleInput(props: PropsT) {
  const { onChange, payCycles, errors } = props;

  const defaultPaymentCycle = {
    type: null,
    paymentCycleDay: "",
    paymentCycleDayOfWeek: "",
    paymentCycleEachDay: "",
    paymentCycleFrequency: "monthly",
    cutOffQuantity: "",
    cutOffFrequency: "days",
    showDaySelector: false,
  };

  const paymentCycleFrequencies = [
    { label: "Monthly", value: "monthly" },
    { label: "Weekly", value: "weekly" },
  ];

  const paymentCycleDays = [
    { label: "First", value: "first" },
    { label: "Second", value: "second" },
    { label: "Third", value: "third" },
    { label: "Fourth", value: "fourth" },
    { label: "Last", value: "last" },
  ];

  const paymentCycleDaysOfTheWeek = [
    ...daysOfTheWeek,
    { label: "Day", value: "day" },
    { label: "Business day", value: "businessDay" },
  ];

  const paymentCycleDayOfTheWeekOtions1 =
    payCycles?.[0]?.paymentCycleFrequency === "weekly"
      ? daysOfTheWeek
      : paymentCycleDaysOfTheWeek;

  const paymentCycleDayOfTheWeekOtions2 =
    payCycles?.[1]?.paymentCycleFrequency === "weekly"
      ? daysOfTheWeek
      : paymentCycleDaysOfTheWeek;

  const pr = new Intl.PluralRules("en-US", { type: "ordinal" });

  const suffixes = new Map([
    ["one", "st"],
    ["two", "nd"],
    ["few", "rd"],
    ["other", "th"],
  ]);

  const removePayCycle = (index: number) => {
    let newPayCycles = [...payCycles];
    if (index === 0) {
      newPayCycles = [defaultPaymentCycle];
    } else {
      newPayCycles.splice(index, 1);
    }
    onChange(newPayCycles);
  };

  const formatOrdinals = (n: number) => {
    const rule = pr.select(n);
    const suffix = suffixes.get(rule);
    return `${suffix}`;
  };

  return (
    <div
      className="PaymentCycle-input flex max-w-2xl flex-col space-y-8"
      data-testid="payment-cycle-input"
    >
      <div className="cycle-1 flex flex-col space-y-4">
        <div className="flex flex-col gap-4 sm:flex-row">
          <span className="flex min-w-20 items-center whitespace-nowrap text-sm font-medium text-gray-900">
            Frequency
          </span>
          <SelectInput
            data-testid="payment-cycle-frequency-1"
            error={errors.paymentCycle1?.paymentCycleFrequency}
            value={paymentCycleFrequencies.find(
              (o) => o.value === payCycles[0].paymentCycleFrequency
            )}
            options={paymentCycleFrequencies}
            onChange={(option) => {
              onChange([
                { ...payCycles[0], paymentCycleFrequency: option.value },
                ...payCycles.splice(1, 1),
              ]);
            }}
            placeholder="Monthly"
          />
        </div>
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="flex min-w-20 whitespace-nowrap">
            <RadioInput
              label="On the"
              value={payCycles[0].type === "on-the"}
              multiSelect={false}
              onClick={() => {
                onChange([
                  {
                    ...payCycles[0],
                    type: "on-the",
                    paymentCycleEachDay: "",
                  },
                  ...payCycles.splice(1, 1),
                ]);
              }}
            />
          </div>

          {payCycles[0].paymentCycleFrequency !== "weekly" && (
            <SelectInput
              data-testid="payment-cycle-day-1"
              error={errors.paymentCycle1?.paymentCycleDay}
              disabled={payCycles[0].type !== "on-the"}
              value={paymentCycleDays.find(
                (o) => o.value === payCycles[0].paymentCycleDay
              )}
              options={paymentCycleDays}
              onChange={(option) => {
                onChange([
                  { ...payCycles[0], paymentCycleDay: option.value },
                  ...payCycles.splice(1, 1),
                ]);
              }}
              placeholder="First"
            />
          )}

          <SelectInput
            data-testid="payment-cycle-day-of-week-1"
            error={errors.paymentCycle1?.paymentCycleDayOfWeek}
            disabled={payCycles[0].type !== "on-the"}
            value={paymentCycleDayOfTheWeekOtions1.find(
              (o) => o.value === payCycles[0].paymentCycleDayOfWeek
            )}
            options={paymentCycleDayOfTheWeekOtions1}
            onChange={(option) => {
              onChange([
                { ...payCycles[0], paymentCycleDayOfWeek: option.value },
                ...payCycles.splice(1, 1),
              ]);
            }}
            placeholder="Friday"
          />
        </div>
        {payCycles[0].paymentCycleFrequency === "monthly" && (
          <div className="flex flex-col gap-4 sm:flex-row">
            <div className="flex min-w-20 whitespace-nowrap">
              <RadioInput
                label="Each"
                value={payCycles[0].type === "each"}
                multiSelect={false}
                onClick={() => {
                  onChange([
                    {
                      ...payCycles[0],
                      type: "each",
                      paymentCycleDay: "",
                      paymentCycleDayOfWeek: "",
                    },
                    ...payCycles.splice(1, 1),
                  ]);
                }}
              />
            </div>
            <div className="flex w-full gap-x-4">
              <div className="relative w-full">
                <TextInput
                  id="payment-cycle-each-day-1"
                  error={errors.paymentCycle1?.paymentCycleEachDay}
                  value={payCycles[0].paymentCycleEachDay}
                  label="Select a day"
                  autoComplete="off"
                  wide
                  onChange={(e) => {
                    // For clear
                    onChange([
                      {
                        ...payCycles[0],
                        paymentCycleEachDay: e.target.value.toString(),
                        showDaySelector: false,
                      },
                      ...payCycles.splice(1, 1),
                    ]);
                  }}
                  onClick={() => {
                    onChange([
                      {
                        ...payCycles[0],
                        showDaySelector: !payCycles[0].showDaySelector,
                      },
                      ...payCycles.splice(1, 1),
                    ]);
                  }}
                  disabled={payCycles[0].type !== "each"}
                  clearable
                />
                <DayOfMonthSelector
                  value={parseInt(payCycles[0].paymentCycleEachDay)}
                  open={payCycles[0].showDaySelector}
                  onSelect={(value) => {
                    onChange([
                      {
                        ...payCycles[0],
                        paymentCycleEachDay: value.toString(),
                        showDaySelector: false,
                      },
                      ...payCycles.splice(1, 1),
                    ]);
                  }}
                />
              </div>
              <div className="flex w-full" />
            </div>
          </div>
        )}

        <div className="flex flex-col justify-end gap-4 sm:flex-row">
          <div className="flex min-w-20 flex-row items-center gap-2">
            <span className="whitespace-nowrap text-sm font-medium text-gray-900">
              Cut off
            </span>

            <Tooltip
              multi={[
                {
                  title: "",
                  subtitle:
                    "Set the cut off time by which all payment requests must be submitted to be processed in the next payment cycle. After this time, any new requests will be processed in the following cycle.",
                },
                {
                  title: "",
                  subtitle: "If you have no cut off, just put in 0.",
                },
              ]}
              position="right"
            >
              <Icon icon="question" size="18" />
            </Tooltip>
          </div>

          <TextInput
            id="payment-cycle-cut-off-amount-1"
            label="Cut off amount"
            number
            min={0}
            max={31}
            error={errors?.paymentCycle1?.cutOffQuantity}
            autoComplete="off"
            value={payCycles[0].cutOffQuantity}
            onChange={(e) => {
              onChange([
                {
                  ...payCycles[0],
                  cutOffQuantity: e.target.value,
                },
                ...payCycles.splice(1, 1),
              ]);
            }}
          />

          <SelectInput
            data-testid="cut-off-frequency-1"
            error={errors?.paymentCycle1?.cutOffFrequency}
            value={[
              { label: "Days before", value: "days" },
              { label: "Weeks before", value: "weeks" },
            ].find((o) => o.value === payCycles[0].cutOffFrequency)}
            options={[
              { label: "Days before", value: "days" },
              { label: "Weeks before", value: "weeks" },
            ]}
            onChange={(o) => {
              onChange([
                {
                  ...payCycles[0],
                  cutOffFrequency: o.value,
                },
                ...payCycles.splice(1, 1),
              ]);
            }}
          />
        </div>
        {payCycles && payCycles[0] && payCycles[0].type && (
          <div className="flex flex-col justify-end sm:flex-row">
            <div className="">
              <Button
                color="transparent"
                data-testid="remove-button-1"
                label="Remove"
                onClick={() => removePayCycle(0)}
              />
            </div>
          </div>
        )}
      </div>

      {payCycles && payCycles.length > 1 && (
        <>
          <div className="border-b" />
          <div className="cycle-2 flex flex-col space-y-4">
            <div className="flex flex-col gap-4 sm:flex-row">
              <span className="flex min-w-20 items-center whitespace-nowrap text-sm font-medium text-gray-900">
                Frequency
              </span>
              <SelectInput
                data-testid="payment-cycle-frequency-2"
                error={errors.paymentCycle2?.paymentCycleFrequency}
                value={paymentCycleFrequencies.find(
                  (o) => o.value === payCycles[1].paymentCycleFrequency
                )}
                options={paymentCycleFrequencies}
                onChange={(option) => {
                  onChange([
                    payCycles[0],
                    { ...payCycles[1], paymentCycleFrequency: option.value },
                  ]);
                }}
                placeholder="Monthly"
              />
            </div>
            <div className="flex flex-col gap-4 sm:flex-row">
              <div className="flex min-w-20 whitespace-nowrap">
                <RadioInput
                  label="On the"
                  value={payCycles[1].type === "on-the"}
                  multiSelect={false}
                  onClick={() => {
                    onChange([
                      payCycles[0],
                      {
                        ...payCycles[1],
                        type: "on-the",
                        paymentCycleEachDay: "",
                      },
                    ]);
                  }}
                />
              </div>

              {payCycles[1].paymentCycleFrequency !== "weekly" && (
                <SelectInput
                  data-testid="payment-cycle-day-2"
                  error={errors.paymentCycle2?.paymentCycleDay}
                  disabled={payCycles[1].type !== "on-the"}
                  value={paymentCycleDays.find(
                    (o) => o.value === payCycles[1].paymentCycleDay
                  )}
                  options={paymentCycleDays}
                  onChange={(option) => {
                    onChange([
                      payCycles[0],
                      { ...payCycles[1], paymentCycleDay: option.value },
                    ]);
                  }}
                  placeholder="First"
                />
              )}

              <SelectInput
                data-testid="payment-cycle-day-of-week-2"
                error={errors.paymentCycle2?.paymentCycleDayOfWeek}
                disabled={payCycles[1].type !== "on-the"}
                value={paymentCycleDayOfTheWeekOtions2.find(
                  (o) => o.value === payCycles[1].paymentCycleDayOfWeek
                )}
                options={paymentCycleDayOfTheWeekOtions2}
                onChange={(option) => {
                  onChange([
                    payCycles[0],
                    {
                      ...payCycles[1],
                      paymentCycleDayOfWeek: option.value,
                    },
                  ]);
                }}
                placeholder="Friday"
              />
            </div>
            {payCycles[1].paymentCycleFrequency === "monthly" && (
              <div className="flex flex-col gap-4 sm:flex-row">
                <div className="flex min-w-20 whitespace-nowrap">
                  <RadioInput
                    label="Each"
                    value={payCycles[1].type === "each"}
                    multiSelect={false}
                    onClick={() => {
                      onChange([
                        payCycles[0],
                        {
                          ...payCycles[1],
                          type: "each",
                          paymentCycleDay: "",
                          paymentCycleDayOfWeek: "",
                        },
                      ]);
                    }}
                  />
                </div>
                <div className="flex w-full gap-x-4">
                  <div className="relative w-full">
                    <TextInput
                      id="payment-cycle-each-day-2"
                      error={errors.paymentCycle2?.paymentCycleEachDay}
                      value={payCycles[1].paymentCycleEachDay}
                      label="Select a day"
                      autoComplete="off"
                      wide
                      onChange={(e) => {
                        onChange([
                          payCycles[0],
                          {
                            ...payCycles[1],
                            paymentCycleEachDay: e.target.value.toString(),
                            showDaySelector: false,
                          },
                        ]);
                      }}
                      onClick={() => {
                        onChange([
                          payCycles[0],
                          {
                            ...payCycles[1],
                            showDaySelector: !payCycles[0].showDaySelector,
                          },
                        ]);
                      }}
                      disabled={payCycles[1].type !== "each"}
                      clearable
                    />
                    <DayOfMonthSelector
                      value={parseInt(payCycles[1].paymentCycleEachDay)}
                      open={payCycles[1].showDaySelector}
                      onSelect={(value) => {
                        onChange([
                          payCycles[0],
                          {
                            ...payCycles[1],
                            paymentCycleEachDay: value.toString(),
                            showDaySelector: false,
                          },
                        ]);
                      }}
                    />
                  </div>
                  <div className="flex w-full" />
                </div>
              </div>
            )}
            <div className="flex flex-col gap-4 sm:flex-row">
              <div className="flex min-w-20 flex-row items-center gap-2">
                <span className="whitespace-nowrap text-sm font-medium text-gray-900">
                  Cut off
                </span>

                <Tooltip
                  multi={[
                    {
                      title: "",
                      subtitle:
                        "Set the cut off time by which all payment requests must be submitted to be processed in the next payment cycle. After this time, any new requests will be processed in the following cycle.",
                    },
                    {
                      title: "",
                      subtitle: "If you have no cut off, just put in 0.",
                    },
                  ]}
                  position="right"
                >
                  <Icon icon="question" size="18" />
                </Tooltip>
              </div>

              <TextInput
                id="payment-cycle-cut-off-amount-2"
                error={errors.paymentCycle2?.cutOffQuantity}
                label="Cut off amount"
                number
                min={0}
                max={31}
                value={payCycles[1].cutOffQuantity}
                autoComplete="off"
                onChange={(e) => {
                  onChange([
                    payCycles[0],
                    {
                      ...payCycles[1],
                      cutOffQuantity: e.target.value,
                    },
                  ]);
                }}
              />

              <SelectInput
                data-testid="cut-off-frequency-2"
                error={errors.paymentCycle2?.cutOffFrequency}
                value={
                  [
                    { label: "Days before", value: "days" },
                    { label: "Weeks before", value: "weeks" },
                  ].find((o) => o.value === payCycles[1].cutOffFrequency) || {
                    label: "Days before",
                    value: "days",
                  }
                }
                options={[
                  { label: "Days before", value: "days" },
                  { label: "Weeks before", value: "weeks" },
                ]}
                onChange={(o) => {
                  onChange([
                    payCycles[0],
                    {
                      ...payCycles[1],
                      cutOffFrequency: o.value,
                    },
                  ]);
                }}
              />
            </div>

            <div className="flex flex-col justify-end sm:flex-row">
              <div className="">
                <Button
                  data-testid="remove-button-2"
                  color="transparent"
                  label="Remove"
                  onClick={() => removePayCycle(1)}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {payCycles && payCycles.length === 1 && (
        <div className="flex justify-center">
          <Button
            color="alternative"
            iconLeft={true}
            size="lg"
            data-testid="add-payment-cycle"
            wide
            icon={
              payCycles && payCycles.length > 1
                ? "minus-circle-fill"
                : "plus-circle"
            }
            label={`${payCycles && payCycles.length > 1 ? "Remove" : "Add"} 2nd Payment schedule`}
            onClick={() => {
              if (payCycles.length > 1) {
                onChange([payCycles[0]]);
              } else {
                onChange([payCycles[0], defaultPaymentCycle]);
              }
            }}
          />
        </div>
      )}

      {errors && (errors.paymentCycle1 || errors.paymentCycle2) && (
        <>
          {errors && errors.paymentCycle1 && errors.paymentCycle1?.type && (
            <InlineAlert
              type="error"
              title="Payment Cycle 1 Error"
              subtitle="Payment Cycle Type is required"
            />
          )}

          {errors && errors.paymentCycle2 && errors.paymentCycle2?.type && (
            <InlineAlert
              type="error"
              title="Payment Cycle 2 Error"
              subtitle="Payment Cycle 2 Type is required"
            />
          )}
        </>
      )}

      {payCycles && payCycles[0]?.type && (
        <p className="w-full border-t pt-6">
          <b>Summary:</b> You have{" "}
          {payCycles && payCycles.length > 1 ? "two" : "one"} payment cycle
          {payCycles && payCycles.length > 1 && "s"} that occur
          {payCycles && payCycles.length === 1 && "s"}{" "}
          {payCycles[0].type === "on-the" ? "on the" : "each"}{" "}
          {payCycles[0].type === "on-the"
            ? `${payCycles[0].paymentCycleDay} ${capitalizeName(payCycles[0].paymentCycleDayOfWeek)} of every ${payCycles[0].paymentCycleFrequency === "weekly" ? "week" : "month"} `
            : `${payCycles[0].paymentCycleEachDay || "n"}${formatOrdinals(parseInt(payCycles[0].paymentCycleEachDay))} day of the month `}
          with a cut off of {payCycles[0].cutOffQuantity || "n"}{" "}
          {payCycles[0].cutOffFrequency || "days"}
          {payCycles.length > 1 ? (
            <>
              {" and "}
              {payCycles[1].type === "on-the" ? "on the" : "each"}{" "}
              {payCycles[1].type === "on-the"
                ? `${payCycles[1].paymentCycleDay} ${capitalizeName(payCycles[1].paymentCycleDayOfWeek)} of every month `
                : `${payCycles[1].paymentCycleEachDay || "n"}${formatOrdinals(parseInt(payCycles[1].paymentCycleEachDay))} day of the month `}
              with a cut off of {payCycles[1].cutOffQuantity || "n"} day.
            </>
          ) : (
            ""
          )}
        </p>
      )}
    </div>
  );
}

import clsx from "clsx";
import dynamic from "next/dynamic";
import { useCallback, useEffect, useState } from "react";
import { SetupQBD, SetupSage300, SetupSageIntacct } from ".";
import { useAuth } from "../../../../state";
import { Button, Icon, Modal } from "../../../shared/components";
import SetupAccumatica from "./setupAccumatica";
import SetupCmic from "./setupCmic";

const RailzWidget = dynamic(
  () => import("../components/railzWidget").then((mod) => mod.default),
  {
    loading: () => <div className="h-[50vh]">Loading...</div>,
    ssr: false,
  }
);

type PropsT = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess: () => void;
  setSelectEntitiesModalOpen: (state: boolean) => void;
};

const ConnectionModal = (props: PropsT) => {
  const { company } = useAuth();
  const { setSelectEntitiesModalOpen, onSuccess, setOpen, open } = props;
  const connection = company?.Connections?.[0];
  const [setupType, setSetupType] = useState<
    null | "railz" | "sageIntacct" | "sage300" | "accumatica" | "cmic" | "QBD"
  >(null);

  const handleSageIntacct = useCallback(() => {
    setSelectEntitiesModalOpen(true);
  }, [setSelectEntitiesModalOpen]);

  useEffect(() => {
    if (connection) {
      setSetupType(
        connection.connection_type as
          | "railz"
          | "sageIntacct"
          | "sage300"
          | "QBD"
      );
    }
  }, [connection]);

  useEffect(() => {
    // To avoid the modal closing before the animation finishes
    if (!open) {
      setTimeout(() => {
        if (!connection) {
          setSetupType(null);
        }
      }, 310);
    }
  }, [connection, open]);

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        if (!connection) {
          setSetupType(null);
        }
      }}
      data-testid="new-connection-modal"
      title="Securely connect your accounting software"
    >
      <div
        className={clsx("w-[90vw]", {
          "md:max-w-4xl": setupType === "railz",
          "md:w-full": setupType !== "railz",
          "sm:max-w-[576px]": setupType === null,
        })}
      >
        <div className="px-4 py-4 sm:px-6 sm:py-5">
          {setupType === null && (
            <div className="flex flex-col gap-y-3">
              <div
                data-testid="sage-300-setup-btn"
                onClick={() => setSetupType("sage300")}
                className="flex w-full items-center justify-start gap-3 rounded-lg border border-gray-200 bg-white p-4 transition-all hover:cursor-pointer hover:border-gray-900"
              >
                <div className="flex w-full items-center justify-start gap-3">
                  <Icon size="38" icon="sage300" />
                  Sage 300
                </div>

                <Icon size="20" icon="caret-right" />
              </div>

              <div
                data-testid="sage-intacct-setup-btn"
                onClick={() => setSetupType("sageIntacct")}
                className="flex w-full items-center justify-start gap-3 rounded-lg border border-gray-200 bg-white p-4 transition-all hover:cursor-pointer hover:border-gray-900"
              >
                <div className="flex w-full items-center justify-start gap-3">
                  <Icon size="38" icon="sageIntacct" />
                  Sage Intacct
                </div>

                <Icon size="20" icon="caret-right" />
              </div>

              <div
                data-testid="accumatica-setup-btn"
                onClick={() => setSetupType("accumatica")}
                className="flex w-full items-center justify-start gap-3 rounded-lg border border-gray-200 bg-white p-4 transition-all hover:cursor-pointer hover:border-gray-900"
              >
                <div className="flex w-full items-center justify-start gap-3">
                  <Icon size="38" icon="accumatica" />
                  Accumatica
                </div>

                <Icon size="20" icon="caret-right" />
              </div>

              <div
                data-testid="accumatica-setup-btn"
                onClick={() => setSetupType("cmic")}
                className="flex w-full items-center justify-start gap-3 rounded-lg border border-gray-200 bg-white p-4 transition-all hover:cursor-pointer hover:border-gray-900"
              >
                <div className="flex w-full items-center justify-start gap-3">
                  <Icon size="38" icon="cmic" />
                  CMIC
                </div>

                <Icon size="20" icon="caret-right" />
              </div>

              {company?.expected_activity === "buyer" && (
                <div
                  data-testid="qbd-setup-btn"
                  onClick={() => setSetupType("QBD")}
                  className="flex w-full items-center justify-start gap-3 rounded-lg border border-gray-200 bg-white p-4 transition-all hover:cursor-pointer hover:border-gray-900"
                >
                  <div className="flex w-full items-center justify-start gap-3">
                    <Icon size="38" icon="QBD" />
                    Quickbooks Desktop
                  </div>

                  <Icon size="20" icon="caret-right" />
                </div>
              )}

              <div className="flex items-center">
                <div className="flex-1 border-t border-gray-200"></div>
                <span className="mx-4 text-sm text-gray-900">OR</span>
                <div className="flex-1 border-t border-gray-200"></div>
              </div>

              <div
                data-testid="railz-setup-btn"
                onClick={() => setSetupType("railz")}
                className="inline-flex w-full flex-col items-center justify-start gap-3 rounded-lg border border-gray-200 bg-white p-4 transition-all hover:cursor-pointer hover:border-gray-900"
              >
                <div className="pb-1 text-center text-xs font-medium text-gray-900">
                  Dynamics 365, Freshbooks, Quickbooks Online, Sage Business
                  Cloud, Oracle NetSuite, Wave, Xero
                </div>
                <div className="flex">
                  {[
                    "dynamicsBusinessCentral",
                    "freshbooks",
                    "quickbooks",
                    "oracleNetsuite",
                    "sageBusinessCloud",
                    "wave",
                    "xero",
                  ].map((service) => (
                    <Icon
                      key={service}
                      iconStyle="-ml-2"
                      icon={service}
                      size="38"
                    />
                  ))}
                </div>
              </div>
              <div className="mt-2 flex justify-end">
                <Button
                  label="Cancel"
                  color={"transparent"}
                  outline
                  onClick={() => setOpen(false)}
                />
              </div>
            </div>
          )}

          {setupType === "railz" && (
            <RailzWidget
              setOpen={() => {
                setOpen(false);
                setSetupType(null);
              }}
              goBack={() => setSetupType(null)}
              railzID={company?.railz_id}
              handleAddedConnection={onSuccess}
            />
          )}

          {setupType === "sageIntacct" && (
            <SetupSageIntacct
              onSuccess={handleSageIntacct}
              onCancel={() => setSetupType(null)}
            />
          )}

          {setupType === "sage300" && (
            <SetupSage300
              setSetupType={setSetupType}
              onSuccess={() => {
                setOpen(false);
              }}
            />
          )}

          {setupType === "accumatica" && (
            <SetupAccumatica
              setSetupType={setSetupType}
              connection={null}
              onSuccess={() => {
                setOpen(false);
              }}
            />
          )}

          {setupType === "cmic" && (
            <SetupCmic
              setSetupType={setSetupType}
              connection={null}
              onSuccess={() => {
                setOpen(false);
              }}
            />
          )}

          {setupType === "QBD" && (
            <SetupQBD
              setSetupType={setSetupType}
              onSuccess={() => {
                setOpen(false);
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ConnectionModal;

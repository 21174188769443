import { ComponentProps, useCallback, useState } from "react";
import { getERPFinancialAccounts } from "../../../../apis/erp";
import { DropdownSearchInput } from "../../../shared/components";
import { IntegrationsChangesT } from "../types";

type PropsT = {
  changes?: IntegrationsChangesT;
  type: string;
  entity: OrganizationalEntityT | null;
  field: keyof IntegrationsChangesT;
  handleChange: <K extends keyof IntegrationsChangesT>(
    key: K,
    value: IntegrationsChangesT[K] | null
  ) => void;
};

type DropdownSearchInputProps = ComponentProps<typeof DropdownSearchInput>;

type UseFinancialAccountsReturnT = {
  dropdown: Pick<DropdownSearchInputProps, "onSelect" | "value" | "getOptions">;
  key: string;
};

export const useFinancialAccount = ({
  entity,
  field,
  handleChange,
  type,
}: PropsT): UseFinancialAccountsReturnT => {
  const [selectedFinancialAccount, setSelectedFinancialAccount] = useState<{
    value: string;
    label: string;
  }>({
    value: "",
    label: "",
  });

  const actualColumn = entity?.[field];

  const getOptions = useCallback(
    async (query: string) => {
      const selectedIds: string[] = [];
      if (actualColumn) {
        selectedIds.push(actualColumn as string);
      }

      const response = await getERPFinancialAccounts({
        search: query,
        selectedIds,
      });

      const options = (response.accounts || []).map((acc) => ({
        label: acc?.name || "",
        value: acc?.erp_id || "",
      }));

      if (actualColumn) {
        const foundAccount = options.find((x) => x.value === actualColumn);
        if (foundAccount) {
          setSelectedFinancialAccount(foundAccount);
        }
      }

      return options;
    },
    [actualColumn]
  );

  return {
    dropdown: {
      onSelect: (option) => {
        handleChange(field, option?.value);
      },
      getOptions,
      value: {
        label: selectedFinancialAccount.label || "",
        value: selectedFinancialAccount.value || "",
      },
    },
    key: `${type}-${selectedFinancialAccount.value}`,
  };
};

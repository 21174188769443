import { useCallback, useState } from "react";
import {
  syncReleaseFundsEntries,
  syncRepaymentEntries,
} from "../../../../apis/erp";
import { useAuth } from "../../../../state";
import { useToast } from "../../../shared/components";
import { getBuyerEPState, getBuyerRepaymentState } from "../helpers";

type PropsT = {
  invoice: InvoiceType;
  isBill: boolean;
  onSuccess?: () => void;
};

export const useJournalEntries = ({ invoice, isBill, onSuccess }: PropsT) => {
  const { company, organizationalEntities } = useAuth();
  const { toast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const isJEReady =
    invoice.early_pay && Boolean(company?.Connections?.[0]) && Boolean(isBill);

  // TODO: This isn't great if you have like, 40+ entities, but it works for now.
  const selectedEntity = company?.Connections?.[0]?.default_to_top_level
    ? organizationalEntities.find((entity) => entity.is_top_level)
    : organizationalEntities.find(
        (entity) => entity.erp_id === invoice.buyer_erp_entity_id
      );

  console.log(selectedEntity);

  const epProcessingState = getBuyerEPState(invoice, selectedEntity);

  const repaymentProcessingState = getBuyerRepaymentState(
    invoice,
    selectedEntity,
    company?.Connections?.[0]
  );

  const isPayNow =
    invoice.invoice_purpose === null && invoice.status === "paid-final";

  const isEPEntriesReady = isJEReady && epProcessingState.state === "ready";

  const isRepaymentEntriesReady =
    isJEReady && repaymentProcessingState.state === "ready";

  const isEntriesProcessing =
    epProcessingState.state === "in-progress" ||
    repaymentProcessingState.state === "in-progress";

  const isWithPendingEntries =
    (isEPEntriesReady && !isPayNow) ||
    isRepaymentEntriesReady ||
    isEntriesProcessing;

  const hidePushSupplierCredit =
    invoice?.status === "paid-final" && !invoice.ep_paid_at;

  const releaseFundsEntries = useCallback(async () => {
    if (isLoading) return;

    const loader = toast({
      title: "Pushing entries...",
      loader: true,
      duration: 35000,
    });

    setIsLoading(true);

    syncReleaseFundsEntries({
      id: invoice?.parent_id ? invoice?.parent_id : invoice?.id,
      type: "buyer",
    })
      .then((res) => {
        if (!res?.success) throw new Error("Failed to push entries");
        loader.update({
          id: loader.id,
          variant: "success",
          title: "Entries pushed successfully!",
          loader: false,
        });
      })
      .catch(() => {
        loader.update({
          id: loader.id,
          variant: "error",
          title: "Error pushing entries",
          loader: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
        onSuccess?.();
        setTimeout(() => loader.dismiss(), 5000);
      });
  }, [invoice?.id, invoice?.parent_id, onSuccess, toast, isLoading]);

  const repaymentEntries = useCallback(async () => {
    if (isLoading) return;

    setIsLoading(true);

    const loader = toast({
      title: "Pushing entries...",
      loader: true,
    });

    await syncRepaymentEntries({
      id: invoice?.parent_id ? invoice?.parent_id : invoice?.id,
      type: "buyer",
      method: "final-payment",
    })
      .then((res) => {
        if (!res?.success) throw new Error("Failed to push entries");
        loader.update({
          id: loader.id,
          variant: "success",
          title: "Entries pushed successfully!",
          loader: false,
        });
      })
      .catch(() => {
        loader.update({
          id: loader.id,
          variant: "error",
          title: "Error pushing entries",
          loader: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
        onSuccess?.();
      });
  }, [invoice?.id, invoice?.parent_id, onSuccess, toast, isLoading]);

  const pushSupplierDiscount = useCallback(async () => {
    if (isLoading) return;

    setIsLoading(true);

    const loader = toast({
      title: "Pushing supplier credits...",
      loader: true,
    });

    await syncRepaymentEntries({
      id: invoice?.parent_id ? invoice?.parent_id : invoice?.id,
      type: "buyer",
      method: "supplier-credit",
    })
      .then((res) => {
        if (!res?.success) throw new Error("Failed to push entries");
        loader.update({
          id: loader.id,
          variant: "success",
          title: "Supplier discount pushed successfully!",
          loader: false,
        });
      })
      .catch(() => {
        loader.update({
          id: loader.id,
          variant: "error",
          title: "Error pushing supplier discount",
          loader: false,
        });
      });

    setIsLoading(false);
    onSuccess?.();
  }, [invoice?.id, invoice?.parent_id, onSuccess, toast, isLoading]);

  return {
    isLoading,
    isJEReady,
    epProcessingState,
    repaymentProcessingState,
    isEPEntriesReady,
    isRepaymentEntriesReady,
    isWithPendingEntries,
    releaseFundsEntries,
    repaymentEntries,
    pushSupplierDiscount,
    hidePushSupplierCredit,
    isPayNow,
  };
};

interface OriginalErrors {
  [key: string]: {
    message: string;
    type: string;
  };
}

interface FormattedErrors {
  [key: string]: string;
}

export const formatErrors = (
  originalErrors: OriginalErrors
): FormattedErrors => {
  const formattedErrors: FormattedErrors = {};

  for (const key in originalErrors) {
    if (Object.prototype.hasOwnProperty.call(originalErrors, key)) {
      formattedErrors[key] = originalErrors[key].message;
    }
  }

  return formattedErrors;
};

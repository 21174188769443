import clsx from "clsx";
import { useState } from "react";
import { forceCompanySync, getCompany } from "../../../../apis/company";
import { useAuth } from "../../../../state";
import { Button, Icon, Tooltip, useToast } from "../../../shared/components";
import { formatDateForRailz } from "../../../shared/helpers";

type PropsT = {
  onDisconnect: () => void;
  connection: ConnectionT;
  lastSyncAt?: string | Date;
  isSetup?: boolean;
  onConnect: () => void;
};

export const ActiveERP = (props: PropsT) => {
  const { company, selectedEntity } = useAuth();
  const { onDisconnect, connection, onConnect, lastSyncAt, isSetup } = props;
  const { toast } = useToast();

  const partiallyConnectedToQBD =
    connection &&
    connection?.is_setup_complete === false &&
    connection.connection_type === "QBD";
  const isQBD = connection?.connection_type === "QBD";

  const [isRequestingForceSync, setIsRequestingForceSync] = useState(false);
  const [isInitiatingAccumatica, setIsInitatingAccumatica] = useState(false);

  const forceSync = async () => {
    setIsRequestingForceSync(true);

    forceCompanySync({
      entityId: isQBD ? selectedEntity?.erp_id?.toString() : undefined,
    })
      .then((response) => {
        if (!response?.success) {
          throw new Error(response?.message || "Failed to sync");
        }
        toast({
          variant: "success",
          title: `Syncing data. Please check back in a few minutes.`,
        });
        getCompany();
      })
      .catch((e) => {
        console.error(e);
        toast({
          variant: "error",
          title: "An error occured while syncing your account.",
          description: e.message || "Please contact support for assistance.",
        });
      })
      .finally(() => setIsRequestingForceSync(false));
  };

  const initiateAccumatica = async () => {
    if (!connection.link_url) return;
    setIsInitatingAccumatica(true);
    window.open(connection.link_url, "_blank");
    setIsInitatingAccumatica(false);
  };

  return (
    <div
      data-testid="active-erp-page"
      className={clsx(
        "ActiveERP flex max-w-full gap-4 rounded-xl border border-gray-200 bg-white px-4 py-5",
        {
          "flex-col": isSetup,
          "flex-row justify-between": !isSetup,
        }
      )}
    >
      <div className="flex w-full items-center justify-start gap-4">
        <Icon icon={connection.connection_type} size="54" />

        <div className="flex flex-col">
          {partiallyConnectedToQBD ? (
            <div className="font-semibold text-gray-900">
              Partially-connected
            </div>
          ) : (
            <div className="font-semibold text-gray-900">Connected</div>
          )}

          {partiallyConnectedToQBD ? (
            <div className="text-sm text-gray-500">
              Please complete the setup process to fully connect
            </div>
          ) : (
            <div className="text-sm text-gray-500">
              {lastSyncAt ? (
                <>Last synced {formatDateForRailz(lastSyncAt)}</>
              ) : (
                "Sync in progress..."
              )}
            </div>
          )}
        </div>
      </div>

      {partiallyConnectedToQBD ? (
        <Button
          size="xs"
          wide={isSetup}
          onClick={onConnect}
          label="Complete connection"
        />
      ) : connection.connection_type === "accumatica" &&
        !connection.is_setup_complete ? (
        <Tooltip position="top" title="Login to your Acumatica account">
          <Button
            color="transparent"
            size="xs"
            wide={isSetup}
            onClick={initiateAccumatica}
            loading={isInitiatingAccumatica}
            disabled={isSetup || !connection.link_url}
            label="Login/Link"
          />
        </Tooltip>
      ) : (
        <div className="flex flex-col gap-2 sm:flex-row">
          <div className={isSetup ? "w-full" : ""}>
            <Button
              color="transparent"
              outline
              size="xs"
              wide={isSetup}
              disabled={isRequestingForceSync}
              onClick={() => forceSync()}
              loading={isRequestingForceSync}
              label="Update"
              icon="arrows-clockwise"
            />
          </div>

          {connection.connection_type === "QBD" && (
            <div className={isSetup ? "w-full" : ""}>
              <Button
                size="xs"
                wide={isSetup}
                outline
                color="transparent"
                onClick={onConnect}
                label="Add another entity"
              />
            </div>
          )}

          <div className={isSetup ? "w-full" : ""}>
            {isRequestingForceSync || company?.is_syncing ? (
              <Tooltip
                position="top"
                title="Unable to disconnect while syncing"
              >
                <Button
                  color="orange"
                  size="xs"
                  wide={isSetup}
                  onClick={onDisconnect}
                  disabled={isRequestingForceSync || company?.is_syncing}
                  label="Disconnect"
                />
              </Tooltip>
            ) : (
              <Button
                color="orange"
                size="xs"
                wide={isSetup}
                onClick={onDisconnect}
                label="Disconnect"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ActiveERP;

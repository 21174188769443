"use client";

import {
  FloatingArrow,
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import clsx from "clsx";
import { ReactNode, useRef, useState } from "react";

type PlacementT =
  | "top"
  | "top-start"
  | "top-end"
  | "right"
  | "right-start"
  | "right-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "left"
  | "left-start"
  | "left-end";

export type PropsT = {
  position?: PlacementT;
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  padding?: number;
  multi?: {
    title?: string;
    subtitle?: string;
    spacer?: boolean;
  }[];
  className?: string;
};

// https://floating-ui.com/docs/tooltip
export const Tooltip = (props: PropsT) => {
  const {
    position = "top",
    title,
    subtitle,
    children,
    className = "",
    padding = 10,
    multi,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: position,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(padding),
      shift(),
      arrow({ element: arrowRef }),
      flip({
        fallbackAxisSideDirection: "start",
      }),
    ],
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  const x = position.includes("bottom") || position.includes("top");
  const y = position.includes("right") || position.includes("left");

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className={`relative w-fit hover:cursor-pointer ${className}`}
        data-testid="tooltip"
      >
        {children}
      </div>

      <FloatingPortal>
        {Boolean(isOpen) && (
          <div
            className="z-30 max-w-64 rounded-md bg-gray-800 px-3 py-2.5 shadow-sm shadow-gray-600"
            data-testid="Tooltip-body"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <FloatingArrow
              className={clsx(
                "border-gray-800 fill-gray-800 outline-gray-800 ring-gray-800",
                {
                  "w-10": x,
                  "h-10": y,
                }
              )}
              context={context}
              height={8}
              ref={arrowRef}
              strokeWidth={2}
              tipRadius={2}
              width={16}
            />

            {multi ? (
              multi?.map((item, idx) => (
                <div key={idx} data-testid={`multi-body-${idx}`}>
                  {item?.title && (
                    <div className="whitespace-pre-line text-left text-sm font-medium text-white">
                      {item.title}
                    </div>
                  )}
                  {item?.subtitle && (
                    <p className="whitespace-pre-line text-left text-xs text-gray-300">
                      {item?.subtitle}
                    </p>
                  )}
                  {item?.spacer && <div className="h-2" />}
                </div>
              ))
            ) : (
              <>
                <div className="Tooltip-title whitespace-pre-line text-left text-sm font-medium text-white">
                  {title}
                </div>
                {subtitle ? (
                  <p className="Tooltip-subtitle mt-1 whitespace-pre-line text-left text-xs text-gray-300">
                    {subtitle}
                  </p>
                ) : null}
              </>
            )}
          </div>
        )}
      </FloatingPortal>
    </>
  );
};

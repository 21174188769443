// Add https:// if a url/string does not contain
export const formatWebsiteUrl = (url: string) => {
  if (url !== "") {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "https://" + url;
    }
    return url;
  } else {
    return "";
  }
};

import { useState } from "react";
import { createManualERPConnection } from "../../../../apis/erp";
import { useAuth } from "../../../../state";
import {
  Button,
  Icon,
  InlineAlert,
  TextInput,
  useToast,
} from "../../../shared/components";

type PropsT = {
  onSuccess: () => void;
  setSetupType: (
    type: null | "railz" | "sageIntacct" | "sage300" | "QBD"
  ) => void;
};

export const SetupQBD = ({ onSuccess, setSetupType }: PropsT) => {
  const { toast } = useToast();
  const { company } = useAuth();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [onboardingURL, setOnboardingURL] = useState("");
  const [entityName, setEntityName] = useState("");

  const connection = company?.Connections?.[0];
  const partiallyConnectedToQBD =
    connection &&
    connection?.is_setup_complete === false &&
    connection.connection_type === "QBD";
  const hasConnected =
    connection?.connection_type === "QBD" && connection?.is_setup_complete;

  const handleCreateQBDConnection = () => {
    // For adding first or new entities
    if (!onboardingURL && !partiallyConnectedToQBD) {
      setLoading(true);
      setError("");
      createManualERPConnection({
        type: "QBD",
        setupComplete: false,
        entityName: entityName || "",
      })
        .then((req) => {
          if (!req?.success) {
            throw new Error(req?.message || "Failed to create connection");
          }
          setOnboardingURL(req.authFlowUrl || "");
        })
        .catch((err) => {
          setError(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (onboardingURL) {
      // For completing setup
      setLoading(true);
      setError("");
      createManualERPConnection({
        type: "QBD",
        setupComplete: true,
      })
        .then((req) => {
          if (!req?.success) {
            throw new Error(req?.message || "Failed to complete connection");
          }
          onSuccess();
        })
        .catch((err) => {
          setError(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(onboardingURL);
    toast({
      variant: "success",
      title: "Saved invite link to clipboard",
    });
  };

  return (
    <div className="flex max-w-lg flex-col gap-6">
      <div className="my-6 flex flex-row items-center justify-center gap-4">
        <Icon size="60" icon="QBD" iconStyle="" />
        <span className="text-3xl font-bold">Quickbooks Desktop</span>
      </div>

      <div className="flex flex-col gap-2">
        <span className="font-medium">
          There are a few steps to connect <strong>Quickbooks Desktop </strong>
          to
          <strong> Quickly</strong>
        </span>

        <span className="ml-4 text-sm">
          {
            "1. First click 'Setup Connection' below which will generate a link."
          }
        </span>
        <span className="ml-4 text-sm">
          {
            "2. Then open the link on your Quickbooks Desktop machine which will walk you through the rest of the installation"
          }
        </span>
        <span className="ml-4 text-sm">
          {
            "3. After you have completed the installation, click 'Finished setting it up'"
          }
        </span>
        <span className="ml-4 text-sm">
          {
            "4. And that's it! We can start syncing with your Quickbooks Desktop!"
          }
        </span>
        {!onboardingURL && (
          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium">
              If you have multiple entities you can differentiate them by adding
              a name.
            </span>
            <TextInput
              label="Entity Name"
              value={entityName}
              required={!hasConnected}
              showRequired
              wide
              onChange={(e) => setEntityName(e.target.value)}
            />
            {hasConnected && (
              <span className="text-xs text-gray-500">
                (If left blank, we will use your companies name.)
              </span>
            )}
          </div>
        )}
      </div>

      {error && (
        <InlineAlert
          type="error"
          title=""
          subtitle={error}
          dismissable={false}
        />
      )}

      {onboardingURL && (
        <>
          <div className="flex flex-row items-center rounded-xl bg-gray-100 px-4">
            <div className="mr-2 flex h-12 items-center overflow-auto whitespace-nowrap text-sm">
              {onboardingURL}
            </div>
            <div>
              <Button
                className="bg-white hover:bg-gray-200"
                icon="clipboard"
                onClick={() => handleCopyLink()}
                color="ghost"
                customIconSize="20"
              />
            </div>
          </div>
        </>
      )}
      <div className="flex justify-end gap-2">
        {!hasConnected && (
          <Button
            onClick={() => setSetupType(null)}
            label="Back"
            color="transparent"
            outline
          />
        )}
        <Button
          onClick={handleCreateQBDConnection}
          label={
            hasConnected && !onboardingURL
              ? "Add another entity"
              : partiallyConnectedToQBD || onboardingURL
                ? "Finished setting it up"
                : "Setup Connection"
          }
          color="dark"
          loading={loading}
        />
      </div>
    </div>
  );
};
